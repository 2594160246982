@import "modules/responsive-type.scss";

$primary: #ff5163;/* MAIN COLOR */
$secondary: #c14053; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

@import url('https://fonts.googleapis.com/css?family=Lobster|Lobster+Two');
// font-family: 'Lobster', cursive;
// font-family: 'Lobster Two', cursive;


html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}


li {
     font-size: 0.8rem;
}

p {
    font-family: 'Lobster Two', cursive;
    font-size: 1rem;
}

h1 { 
font-family: 'Lobster', cursive; 
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {  
    font-family: 'Lobster', cursive;
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
    font-family: 'Lobster', cursive;
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background-color: #ff5163;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    .navbar-nav {
        >li>a {
            font-family: 'Lobster Two', cursive;
            text-align: center;
            margin-top: 27px;
            display: flex;
            align-items: center;
            color: $wht;
            border-radius: $border-radius;
            transition-duration: 0.5s;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $secondary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $blk;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 40px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 150px;
    }
    .navbar-toggle {
        margin-top: 15px;
    }
}

.top-pad {
    padding: 3em 0em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em 0em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 991px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {

     font-family: 'Lobster Two', cursive;
        font-size: 1rem;
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.shadowthis {
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.75);
}

.shadowthat {
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25), 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
}

.intro{
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/intro.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0%;
    padding: 150px 0px;

    @media(max-width: 991px){
        background-position: 50% 0%;
        padding: 0px 0px;
    }

    @media(max-width: 767px){
        background-position: 0% 0%;
        padding: 0px 0px;
    }

    @media(max-width: 568px){
        background-position: 4% 0%;
        padding: 0px 0px;
    }

    @media(max-width: 320px){
        background-position: 18% 0%;
        padding: 0px 0px;
       
    }
    


    h1, p{
        color:#fff;
    }

    .pushdown{
        padding-top: 33%;
    }
}

.wrapper{
    max-width: 1500px;
    margin: 0px auto;
}


.extrainfo{
    padding: 50px 15px;
}


.divider{
    background-color: #fff;
    padding: 20px 0px;

    h1{
        color:#333;
    }

    .img1{
        background: url(../img/img1.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        padding: 180px 0px;
        border-radius: 10px;
        border: 5px solid $primary;


        @media(max-width: 991px){
            background-position: 50% 40%;
            
        }

        @media(max-width: 767px){
            background-position: 50% 40%;
            
        }

        @media(max-width: 568px){
            background-position: 50% 40%;
           
        }

        @media(max-width: 320px){
            background-position: 50% 40%;
           
        }
    }
}

.divider2{
    background-color: #fff;
    padding: 20px 0px;

    h1{
        color:#333;
    }

    .img2{
        background: url(../img/img2.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 0%;
        padding: 180px 0px;
        border-radius: 10px;
        border: 5px solid $primary;
    
        @media(max-width: 991px){
            background-position: 50% 40%;
            
        }

        @media(max-width: 767px){
            background-position: 50% 40%;
            
        }

        @media(max-width: 568px){
            background-position: 50% 40%;
           
        }

        @media(max-width: 320px){
            background-position: 50% 40%;
           
        }
    }
}

.outro{
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/outro.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 40%;
    padding: 400px 0px 100px;

    @media(max-width: 991px){
        background-position: 50% 40%;
        padding: 300px 0px 100px;
    }

    @media(max-width: 767px){
        background-position: 50% 40%;
        padding: 225px 0px 80px;
    }

    @media(max-width: 568px){
        background-position: 50% 40%;
        padding: 175px 0px 50px;
    }

    @media(max-width: 320px){
        background-position: 50% 40%;
        padding: 100px 0px 25px;
    }

  h1{
        // padding-top: 50px; 
        color: #fff;
    }
}

.btn {
  font-family: 'Quicksand', sans-serif;
  background-color: $primary;
  // border-color: rgba(255,255,255,1); 
  font-size: 15px;
  padding: 1em 2em;
  color: $wht;

    &:hover {
        background-color: $secondary; 
        //border-color: rgba(255,255,255,1); 
        color: $wht;

        @media (max-width: 1366px) {
            background-color: $primary;
            color: $wht;
        }

    &:active, &:focus {
        background-color: $primary;
        color: $wht !important;
        }
    }
}